import React, {Component} from 'react';

class Home extends Component {

    constructor(probs) {
        super(probs)

        //  Initiate state current page number and animation number of control.ed page
        this.state = {
            pageNumber: 0,
            controlPageAnimation: 0,
            captionPageAnimation: 0,
            installationStatus: 0
        }
    }

    //  Method to change the page number
    //  Maintain the animation initialization
    goToPage = (number) => {

        //  Calling clear animation method
        this.clearControlPageAnimation(number)

        //  Stop video
        this.stopVideoLeavingPage()

        //  Between page animation disable scroll over flow for a better visual experience
        document.getElementsByTagName("BODY")[0].style.overflowY = "hidden";
        setTimeout(() => {
            document.getElementsByTagName("BODY")[0].style.overflowY = "auto";
        }, 350);
        const installationEl = document.getElementById('captioned-state');

        this.setState({
            pageNumber: number
        })

        if (number === 1 ) {
            if (installationEl.dataset.installed && installationEl.dataset.loggedIn ) {
                setTimeout(() => {
                    this.goToPage(2)
                }, 2000);
            }
            else if( installationEl.dataset.installed ){
                setTimeout(() => {
                    this.setState({
                        installationStatus: 2
                    })
                    this.goToPage(3)
                }, 2000);
            }
            else{
                setTimeout(() => {
                    this.setState({
                        installationStatus: 1
                    })
                    this.goToPage(3)
                }, 2000);
            }
        }

        //  Temporary Code 1 : 1.2 second waiting effect until extension integration
        if( number === 6 ){
            //document.getElementById('videoId').addEventListener('loadedmetadata', function() {
            //    this.currentTime = 0.1;
            //}, false);
        }

        //  Calling  animation method
        this.controlPageAnimation(number)


    }

    goToPinPage = () => {
        this.setState({
            installationStatus: 0
        })

        this.goToPage(3)
    }

    //  Method to stop video player
    stopVideoLeavingPage = (number) => {

        let videoElement = document.getElementById('videoId')

        if(videoElement){
            videoElement.pause();
        }

    }

    //  Method to clear animation for control.ed page
    clearControlPageAnimation = (number) => {
        //if( number !== 4 && number !== 1 && number !== 5  ){

            let id = window.setTimeout(function() {}, 0);

            while (id--) {
                window.clearTimeout(id);
            }

            this.setState({
                controlPageAnimation: 0,
                captionPageAnimation: 0
            })
        //}
    }

    //  Method to initiate and manage animation for control.ed page
    controlPageAnimation = (number) => {
        if( number === 4 ){

            //  Setting time out after animation in a row
            setTimeout(() => {

                //  End state of first text's animation
                this.setState({
                    controlPageAnimation: 1
                })

                //  End state of first text's arrow's animation
                setTimeout(() => {
                    this.setState({
                        controlPageAnimation: 2
                    })

                    //  End state of second text's animation
                    setTimeout(() => {
                        this.setState({
                            controlPageAnimation: 3
                        })

                        //  End state of second text's arrow's animation
                        setTimeout(() => {
                            this.setState({
                                controlPageAnimation: 4
                            })

                            //  End state of third text's animation
                            setTimeout(() => {
                                this.setState({
                                    controlPageAnimation: 5
                                })

                                //  End state of third text's arrow's animation
                                setTimeout(() => {
                                    this.setState({
                                        controlPageAnimation: 6
                                    })

                                    //  End state of fourth text's animation
                                    setTimeout(() => {
                                        this.setState({
                                            controlPageAnimation: 7
                                        })

                                        //  End state of fourth text's arrow's animation
                                        setTimeout(() => {
                                            this.setState({
                                                controlPageAnimation: 8
                                            })
                                        }, 250);

                                    }, 450);

                                }, 850);

                            }, 450);

                        }, 850);

                    }, 450);

                }, 850);

            }, 350);
        }

        if( number === 5 ){

            //  Setting time out after animation in a row
            setTimeout(() => {

                //  End state of first text's animation
                this.setState({
                    captionPageAnimation: 1
                })

                //  End state of first text's arrow's animation
                setTimeout(() => {
                    this.setState({
                        captionPageAnimation: 2
                    })

                    //  End state of second text's animation
                    setTimeout(() => {
                        this.setState({
                            captionPageAnimation: 3
                        })

                        //  End state of second text's arrow's animation
                        setTimeout(() => {
                            this.setState({
                                captionPageAnimation: 4
                            })

                        }, 850);

                    }, 450);

                }, 850);

            }, 350);
        }

    }

    //  Welcome page source code
    WelcomePage = () => {

        let tempClasses = 'welcome-page-container'

        //  Changing class for the passed pages
        if( this.state.pageNumber !== 0 ){
            tempClasses += ' past-page'
        }

        return <div className={tempClasses}>
            <p className='welcome-page-small-header'>
                Welcome to
            </p>
            <div className='welcome-page-logo-container'>
                <img alt='' className='welcome-page-logo' src='img/LogoMain.svg'/>
                <p className='welcome-page-main-header'>
                    Caption.Ed
                </p>
            </div>
            <p className='welcome-page-description'>
                Caption.Ed helps you get the most out of your meetings, lectures and much more by providing you with captions and a full transcript.
                <br/><br/>
                Our aim is to make any media as accessible as possible.
            </p>
            <button onClick={ e => { this.goToPage(1)} } className='welcome-page-start-button'>
                Let's Get Started!
            </button>
        </div>
    }

    //  Page indicator dots source code
    pageIndicatorDots = () => {

        let tempClasses = 'page-indicator-dots-container'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber === 0 ){
            tempClasses += ' next-page'
        }

        //  Initiate for each 5 dots
        let indicatorClass1 = 'page-indicator-dot'
        let indicatorClass2 = 'page-indicator-dot'
        let indicatorClass3 = 'page-indicator-dot'
        let indicatorClass4 = 'page-indicator-dot'
        let indicatorClass5 = 'page-indicator-dot'

        //  Setting active class according to current page number
        if( this.state.pageNumber === 1 ){
            indicatorClass1 += ' page-indicator-dot-filled'
        }
        if( this.state.pageNumber === 2 || this.state.pageNumber === 3 ){
            indicatorClass2 += ' page-indicator-dot-filled'
        }
        if( this.state.pageNumber === 4 ){
            indicatorClass3 += ' page-indicator-dot-filled'
        }
        if( this.state.pageNumber === 5 ){
            indicatorClass4 += ' page-indicator-dot-filled'
        }
        if( this.state.pageNumber === 6 ){
            indicatorClass5 += ' page-indicator-dot-filled'
        }

        return <div className={tempClasses}>
            <div onClick={ e => { this.goToPage(1)} } className={indicatorClass1}>
            </div>
            <div onClick={ e => { this.goToPage(2)} } className={indicatorClass2}>
            </div>
            <div onClick={ e => { this.goToPage(4)} } className={indicatorClass3}>
            </div>
            <div onClick={ e => { this.goToPage(5)} } className={indicatorClass4}>
            </div>
            <div onClick={ e => { this.goToPage(6)} } className={indicatorClass5}>
            </div>
        </div>
    }

    //<img alt='' className='check-caption-ed-page-logo' src='img/LogoMain.svg'/>
    //  Check extension page's source code
    checking = () => {

        let tempClasses = 'how-to-use-page-container'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber === 0 ){
            tempClasses += ' next-page'
        }
        else if( this.state.pageNumber > 1 ){
            tempClasses += ' past-page'
        }

        return <div className={tempClasses} id='installation-checker'>
            <p className='how-to-use-page-main-header how-to-use-page-main-header-center-align'>
                Let’s check you’ve got Caption.Ed installed…
            </p>
            <img alt='' className='check-caption-ed-page-logo' src='img/captionEdLogo.gif'/>

        </div>
    }

    //  Open Caption.Ed page's source code
    openCaptionEd = () => {

        let tempClasses = 'how-to-use-page-container how-to-use-page-container-open-caption-ed'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber < 2 ){
            tempClasses += ' next-page'
        }
        else if ( this.state.pageNumber > 2 ){
            tempClasses += ' past-page'
        }

        return <div className={tempClasses}>
            <p className='how-to-use-page-small-header'>
                Awesome! Caption.Ed is installed!
            </p>
            <p className='how-to-use-page-main-header how-to-use-page-main-header-open-caption-ed'>
                You open Caption.Ed by clicking this icon next to the address bar
            </p>
            <img alt='' className='open-caption-ed-logo' src='img/extensionIconRound.svg'/>
            <img alt='' className='open-caption-ed-showing-arrow hidden-xxl hidden-xl hidden-lg hidden-md hidden-sm' src='img/arrows/ExtensionArrow-118px.png'/>
            <img alt='' className='open-caption-ed-showing-arrow hidden-xxl hidden-xl hidden-lg hidden-md hidden-xs' src='img/arrows/ExtensionArrow-212px.png'/>
            <img alt='' className='open-caption-ed-showing-arrow hidden-xxl hidden-xl hidden-lg hidden-sm hidden-xs' src='img/arrows/ExtensionArrow-420px.png'/>
            <img alt='' className='open-caption-ed-showing-arrow hidden-xxl hidden-xl hidden-md hidden-sm hidden-xs' src='img/arrows/ExtensionArrow-420px.png'/>
            <img alt='' className='open-caption-ed-showing-arrow hidden-xxl hidden-lg hidden-md hidden-sm hidden-xs' src='img/arrows/ExtensionArrow-661px.png'/>
            <img alt='' className='open-caption-ed-showing-arrow hidden-xl hidden-lg hidden-md hidden-sm hidden-xs' src='img/arrows/ExtensionArrow-911px.png'/>
            <img alt='' className='open-caption-website-bar-image' src='img/addressBar.svg'/>
            <button onClick={ e => { this.goToPage(4)} } className='how-to-use-pages-default-button'>
                All good, next please
            </button>
            <button onClick={ e => { this.goToPinPage() } } className='how-to-use-pages-secondary-button'>
                I don't see this button.
            </button>
        </div>
    }

    loginCaptionEd = () => {

        let tempClasses = 'how-to-use-page-container how-to-use-page-container-open-caption-ed'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber < 3 ){
            tempClasses += ' next-page'
        }
        else if ( this.state.pageNumber > 3 ){
            tempClasses += ' past-page'
        }

        if( this.state.installationStatus === 2 ){
            return <div className={tempClasses}>
                <p className='how-to-use-page-small-header'>
                    Access
                </p>
                <p className='how-to-use-page-main-header'>
                    Login with the email address and password that you registered
                </p>
                <p className='login-info-page-info-text-1'>
                    1. Email address goes here<br/> eg. your uni email address
                </p>
                <img alt='' className='login-info-page-arrow-1-img' src='img/Access-ArrowTop.svg'/>
                <img alt='' className='login-info-page-arrow-2-img' src='img/Access-ArrowBottom.svg'/>
                <img alt='' className='login-info-page-img' src='img/Login-Graphic.svg'/>
                <p className='login-info-page-info-text-2'>
                    2. Password goes here
                </p>
                <p className='login-info-page-info-text-3'>
                    If you have a license key instead, just select “License Key” and enter it there.
                </p>
                <button onClick={ e => { this.goToPage(2)} }  className='how-to-use-pages-default-button how-to-use-pages-default-button-without-left'>
                    Thanks, i’m logged in
                </button>
            </div>
        }
        else if( this.state.installationStatus === 0 ){
            return <div className={tempClasses}>
                <p className='how-to-use-page-small-header'>
                    Pin Caption.Ed
                </p>
                <p className='how-to-use-page-main-header'>
                    No Problemo. <br/>
                    Let’s get that sorted.
                </p>
                <p className='pin-ed-page-list-text-1'>
                    1. Click the jigsaw icon.
                    <img alt='' className='pin-ed-page-extension-icon' src='img/extensionIcon.svg'/>
                </p>
                <p className='pin-ed-page-list-text-2'>
                    2. Click the “Pin” icon next to Caption.Ed
                </p>
                <p className='pin-ed-page-list-tip'>
                    Tip: It should turn <span>blue</span>
                </p>
                <img alt='' className='pin-ed-page-pinned-circle-image' src='img/ArrowPinCaptionEd.svg'/>
                <img alt='' className='pin-ed-page-pinned-circle-part' src='img/Oval.svg'/>
                <img alt='' className='pin-ed-page-pup-up-image' src='img/ExtensionsPopup.svg'/>
                <button onClick={ e => { this.goToPage(4)} } className='how-to-use-pages-default-button how-to-use-pages-default-button-without-left'>
                    That worked- show me more….
                </button>
                <button onClick={ e => { this.setState({ installationStatus: 1 }) } } className='how-to-use-pages-secondary-button how-to-use-pages-default-button-without-left'>
                    No dice - please help!
                </button>
            </div>
        }
        else{
            return this.installation()
        }
    }

    //  Pin Caption.Ed page's source code
    pinCaptionEd = () => {

        let tempClasses = 'how-to-use-page-container how-to-use-page-container-open-caption-ed'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber < 3 ){
            tempClasses += ' next-page'
        }
        else if ( this.state.pageNumber > 3 ){
            tempClasses += ' past-page'
        }

        if( this.state.installationStatus === 0 ){
            return <div className={tempClasses}>
                <p className='how-to-use-page-small-header'>
                    Pin Caption.Ed
                </p>
                <p className='how-to-use-page-main-header'>
                    No Problemo. <br/>
                    Let’s get that sorted.
                </p>
                <p className='pin-ed-page-list-text-1'>
                    1. Click the jigsaw icon.
                    <img alt='' className='pin-ed-page-extension-icon' src='img/extensionIcon.svg'/>
                </p>
                <p className='pin-ed-page-list-text-2'>
                    2. Click the “Pin” icon next to Caption.Ed
                </p>
                <p className='pin-ed-page-list-tip'>
                    Tip: It should turn <span>blue</span>
                </p>
                <img alt='' className='pin-ed-page-pinned-circle-image' src='img/ArrowPinCaptionEd.svg'/>
                <img alt='' className='pin-ed-page-pinned-circle-part' src='img/Oval.svg'/>
                <img alt='' className='pin-ed-page-pup-up-image' src='img/ExtensionsPopup.svg'/>
                <button onClick={ e => { this.goToPage(4)} } className='how-to-use-pages-default-button how-to-use-pages-default-button-without-left'>
                    That worked- show me more….
                </button>
                <button onClick={ e => { this.setState({ installationStatus: 1 }) } } className='how-to-use-pages-secondary-button how-to-use-pages-default-button-without-left'>
                    No dice - please help!
                </button>
            </div>
        }
        else{
            return this.installation()
        }
    }

    //  Controller of Caption.Ed page's source code
    controls = () => {

        let tempClasses = 'how-to-use-page-container how-to-use-page-container-open-caption-ed'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber < 4 ){
            tempClasses += ' next-page'
        }
        else if ( this.state.pageNumber > 4 ){
            tempClasses += ' past-page'
        }

        //  Initializing classes to animate page content
        let controlAnimationText1Classes = 'how-to-use-page-control-image-text-1 '
        let controlAnimationText1ArrowClasses = 'how-to-use-page-control-image-text-arrow-1 '
        let controlAnimationText2Classes = 'how-to-use-page-control-image-text-2 '
        let controlAnimationText2ArrowClasses = 'how-to-use-page-control-image-text-arrow-2 '
        let controlAnimationText3Classes = 'how-to-use-page-control-image-text-3 '
        let controlAnimationText3ArrowClasses = 'how-to-use-page-control-image-text-arrow-3 '
        let controlAnimationText4Classes = 'how-to-use-page-control-image-text-4 '
        let controlAnimationText4ArrowClasses = 'how-to-use-page-control-image-text-arrow-4 '

        //  Setting classes according to animation number
        //  Animation number is changed by controlPageAnimation Method above
        if( this.state.controlPageAnimation === 0 ){
            controlAnimationText1Classes += 'how-to-use-page-control-image-text-1-before-animation'
        }
        if( this.state.controlPageAnimation < 2 ){
            controlAnimationText1ArrowClasses += 'how-to-use-page-control-image-text-arrow-1-before-animation'
        }
        if( this.state.controlPageAnimation < 3 ){
            controlAnimationText2Classes += 'how-to-use-page-control-image-text-2-before-animation'
        }
        if( this.state.controlPageAnimation < 4 ){
            controlAnimationText2ArrowClasses += 'how-to-use-page-control-image-text-arrow-2-before-animation'
        }
        if( this.state.controlPageAnimation < 5 ){
            controlAnimationText3Classes += 'how-to-use-page-control-image-text-3-before-animation'
        }
        if( this.state.controlPageAnimation < 6 ){
            controlAnimationText3ArrowClasses += 'how-to-use-page-control-image-text-arrow-3-before-animation'
        }
        if( this.state.controlPageAnimation < 7 ){
            controlAnimationText4Classes += 'how-to-use-page-control-image-text-4-before-animation'
        }
        if( this.state.controlPageAnimation < 8 ){
            controlAnimationText4ArrowClasses += 'how-to-use-page-control-image-text-arrow-4-before-animation'
        }

        return <div className={tempClasses}>
            <p className='how-to-use-page-small-header'>
                Controls
            </p>
            <p className='how-to-use-page-main-header'>
                These are your Caption.Ed controls.
            </p>
            <img alt='' className={controlAnimationText1ArrowClasses} src='img/controlTextArrow1.svg'/>
            <p className={controlAnimationText1Classes}>
                1. Start and Stop Captions<br/> By Pressing Here
            </p>
            <img alt='' className={controlAnimationText2ArrowClasses} src='img/controlTextArrow2.png'/>
            <p className={controlAnimationText2Classes}>
                2. Your subject personalises your captions to the subject matter your captioning
            </p>
            <img alt='' className={controlAnimationText3ArrowClasses} src='img/controlTextArrow3.svg'/>
            <p className={controlAnimationText3Classes}>
                3. Text Size makes your captions bigger or smaller
            </p>
            <img alt='' className={controlAnimationText4ArrowClasses} src='img/controlTextArrow4.svg'/>
            <p className={controlAnimationText4Classes}>
                4. These changes the colours of your captions
            </p>
            <img alt='' className='how-to-use-control-image' src='img/googleChromeExtension.svg'/>
            <button onClick={ e => { this.goToPage(5)} } className='how-to-use-pages-default-button'>
                That makes sense, next please
            </button>
        </div>
    }

    //  Window of Caption.Ed page's source code
    captionWindow = () => {

        let tempClasses = 'how-to-use-page-container how-to-use-page-container-open-caption-ed'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber < 5 ){
            tempClasses += ' next-page'
        }
        else if ( this.state.pageNumber > 5 ){
            tempClasses += ' past-page'
        }

        //  Initializing classes to animate page content
        let captionAnimationText1Classes = 'how-to-use-page-caption-image-text-1 '
        let captionAnimationText1ArrowClasses = 'how-to-use-page-caption-image-text-arrow-1 '
        let captionAnimationText2Classes = 'how-to-use-page-caption-image-text-2 '
        let captionAnimationText2ArrowClasses = 'how-to-use-page-caption-image-text-arrow-2 '

        //  Setting classes according to animation number
        //  Animation number is changed by captionPageAnimation Method above
        if( this.state.captionPageAnimation === 0 ){
            captionAnimationText1Classes += 'how-to-use-page-caption-image-text-1-before-animation'
        }
        if( this.state.captionPageAnimation < 2 ){
            captionAnimationText1ArrowClasses += 'how-to-use-page-caption-image-text-arrow-1-before-animation'
        }
        if( this.state.captionPageAnimation < 3 ){
            captionAnimationText2Classes += 'how-to-use-page-caption-image-text-2-before-animation'
        }
        if( this.state.captionPageAnimation < 4 ){
            captionAnimationText2ArrowClasses += 'how-to-use-page-caption-image-text-arrow-2-before-animation'
        }

        return <div className={tempClasses}>
            <p className='how-to-use-page-small-header'>
                Caption Window
            </p>
            <p className='how-to-use-page-main-header'>
                This is where your <br/>captions will appear
            </p>
            <img alt='' className={captionAnimationText1ArrowClasses} src='img/controlTextArrow1.svg'/>
            <p className={captionAnimationText1Classes}>
                1. Your captions will appear in a <br/>popup window when you press play
            </p>
            <img alt=''  className={captionAnimationText2ArrowClasses} src='img/captionWindowArrow2.svg'/>
            <p className={captionAnimationText2Classes}>
                2. You can download a full transcript <br/> of your captions by clicking here
            </p>
            <a download className='how-to-use-page-caption-hidden-link' href='/img/temporaryFile.txt'>Download</a>
            <div className='how-to-use-caption-window-image-as-html'>
                <div className='how-to-use-caption-window-image-as-html-circle-1'>

                </div>
                <div className='how-to-use-caption-window-image-as-html-circle-2'>

                </div>
                <div className='how-to-use-caption-window-image-as-html-circle-3'>

                </div>
                <img className='how-to-use-caption-window-image-as-html-logo' src='img/smallLogo.svg'/>
                <p className='how-to-use-caption-window-image-as-html-text'>
                    This is where your captions will be displayed on your screen...
                </p>
            </div>
            <button onClick={ e => { this.goToPage(6)} } className='how-to-use-pages-default-button how-to-use-pages-default-button-without-left'>
                I’m ready to try it out!
            </button>
        </div>
    }

    //  Testing Caption.Ed page's source code
    mediaPlayer = () => {

        let tempClasses = 'how-to-use-page-container how-to-use-page-container-open-caption-ed'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber < 6 ){
            tempClasses += ' next-page'
        }
        else if ( this.state.pageNumber > 6 ){
            tempClasses += ' past-page'
        }

        return <div className={tempClasses}>
            <p className='how-to-use-page-small-header'>
                Ready to go?
            </p>
            <p className='how-to-use-page-main-header how-to-use-page-main-header-open-caption-ed'>
                Let’s Try It Out!
            </p>
            <div className='media-player-page-steps-container'>
                <p className='media-player-page-steps'>
                    1. Open Caption.Ed
                    <img alt='' onClick={ e => { this.goToPage(2)} } className='media-player-page-step-1-question' src='img/questionMark.png'/>
                    <img alt='' className='media-player-ed-logo' src='img/ReadyToGoLogoIcon.svg'/>
                </p>
                <p className='media-player-page-steps media-player-page-step-2'>
                    2. Press “Start” in Caption.Ed
                    <img alt='' onClick={ e => { this.goToPage(4)} } className='media-player-page-step-2-question' src='img/questionMark.png'/>
                    <img alt='' className='media-player-ed-logo' src='img/ReadyToGoPlayIcon.svg'/>
                </p>
                <p className='media-player-page-steps'>
                    3. Start the Video Below
                </p>
            </div>

            <img alt='' className='media-player-ed-showing-arrow hidden-xxl hidden-xl hidden-lg hidden-md hidden-sm' src='img/arrows/ReadyToGo-Arrow-109px.png'/>
            <img alt='' className='media-player-ed-showing-arrow hidden-xxl hidden-xl hidden-lg hidden-md hidden-xs' src='img/arrows/ReadyToGo-Arrow-181px.png'/>
            <img alt='' className='media-player-ed-showing-arrow hidden-xxl hidden-xl hidden-lg hidden-sm hidden-xs' src='img/arrows/ReadyToGo-Arrow-389px.png'/>
            <img alt='' className='media-player-ed-showing-arrow hidden-xxl hidden-xl hidden-md hidden-sm hidden-xs' src='img/arrows/ReadyToGo-Arrow-500px.png'/>
            <img alt='' className='media-player-ed-showing-arrow hidden-xxl hidden-lg hidden-md hidden-sm hidden-xs' src='img/arrows/ReadyToGo-Arrow-700px.png'/>
            <img alt='' className='media-player-ed-showing-arrow hidden-xl hidden-lg hidden-md hidden-sm hidden-xs' src='img/arrows/ReadyToGo-Arrow-949px.png'/>
            <div className='media-player-page-video-container'>
                <video
                    id="my-video"
                    className="video-js media-player-page-video"
                    controls
                    preload="auto"
                    width="508"
                    height="286"
                    data-setup="{}"
                >
                    <source src="video/onBoardingVideo.mp4#t=0.01" type="video/mp4"/>
                    <source src="video/onBoardingVideo.mp4#t=0.01" type="video/webm"/>
                    <p className="vjs-no-js">
                        To view this video please enable JavaScript, and consider upgrading to a
                        web browser that
                        <a href="https://videojs.com/html5-video-support/" target="_blank"
                        >supports HTML5 video</a
                        >
                    </p>
                </video>
                <a href='https://captioned.talk-type.com/' className='how-to-use-pages-default-button how-to-use-pages-default-button-center'>
                    Click here to see your Caption.Ed Dashboard
                </a>
            </div>
        </div>
    }

    //  Open Installation source code
    installation = () => {

        let tempClasses = 'how-to-use-page-container'

        //  Changing class according to current page : can be next-page or past-page
        if( this.state.pageNumber < 3 ){
            tempClasses += ' next-page'
        }
        else if ( this.state.pageNumber > 3 ){
            tempClasses += ' past-page'
        }

        return <div className={tempClasses}>
            <p className='how-to-use-page-small-header'>
                Installation
            </p>
            <p className='how-to-use-page-main-header'>
                Check that you’ve got Caption.Ed installed by visiting the Chrome
            </p>
            <p className='media-player-page-steps media-player-page-steps-add-chrome-button-margin'>
                In the Chrome Web Store, click the “Add to Chrome” button on the top right side of the screen. It looks like this:
            </p>
            <img class="installation-page-add-chrome-button-arrow" src="img/installationArrow.svg"/>
            <button className='installation-page-add-chrome-button'>
                Add to Chrome
            </button>
            <a target='_blank' href='https://chrome.google.com/webstore/detail/captioned/aokjakfeailcmenjjgmbcepkfkcfgpie' className='how-to-use-pages-default-button'>
                Open Chrome Web Store
            </a>
            <a target='_blank' href='https://help.carescribe.io/en/' className='how-to-use-pages-secondary-button'>
                Contact Caption.Ed Support
            </a>
        </div>
    }

    render() {

        return <div style={{backgroundImage: "url(../img/roundbackground.png)",backgroundPosition: 'center',backgroundSize: '',backgroundRepeat: 'no-repeat',backgroundPositionY: '140px',height: '100vh',backgroundPositionX: 'calc( 50% + 375px )',}} className="App">
            <div className='header-container'>
                <a href='https://www.caption-ed.com/' >
                    <img alt='' className='header-logo' src='img/LogoMain.svg'/>
                </a>
                <a href='https://help.carescribe.io/en/' className='header-app-button'>
                    Help
                </a>
            </div>
            <div className='content-container'>
                {this.WelcomePage()}
                {this.pageIndicatorDots()}
                {this.checking()}
                {this.openCaptionEd()}
                {this.loginCaptionEd()}
                {this.controls()}
                {this.captionWindow()}
                {this.mediaPlayer()}
            </div>
        </div>
    }

}

export default Home;